import React, { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  User,
  onAuthStateChanged,
  signOut as firebaseSignOut,
  getAuth,
} from "firebase/auth";
import { auth } from "../firebase";
import { TotalUserData } from "./types";
import { toast } from "react-toastify";
import { komApiClient } from "./apiClient";

interface AuthContextType {
  user: FirebaseUser | null;
  userData: TotalUserData | undefined;
  setUserData: React.Dispatch<React.SetStateAction<TotalUserData | undefined>>;
  googleSignIn: () => void;
  signOut: () => void;
  fetchUserData: (email: string | null | undefined) => Promise<void>;
}

interface FirebaseUser extends User {}
interface AuthContextProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [userData, setUserData] = useState<TotalUserData | undefined>();

  const fetchUserData = async (email: string | null | undefined) => {
    if (email) {
      try {
        const res = await komApiClient.get(`/affiliate/${email}`);
        if (res.status === 200) {
          setUserData(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
      fetchUserData(currentUser?.email);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const signOut = () => {
    firebaseSignOut(auth);
    setUser(null);
    setUserData(undefined);
  };

  const googleSignIn = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
      const val = await signInWithPopup(auth, provider);

      // Check if the user is an affiliate in the backend
      const isAffiliate = await checkIfAffiliate(val.user.email);

      if (!isAffiliate) {
        signOut();
        // Show error toast and prevent sign-in if user is not an affiliate
        toast.error("Sign In unsuccessful. Please register as an affiliate.");
        return;
      }

      // If the user is an affiliate, proceed with the sign-in
      toast.success("Sign In Successful!");

      fetchUserData(val.user.email);
    } catch (error) {
      console.error("Google Sign-in Error:", error);
      toast.error("Google Sign-in Failed!");
    }
  };

  // Function to check if the user is an affiliate in your backend
  const checkIfAffiliate = async (email: string | null): Promise<boolean> => {
    if (!email) {
      return false;
    }

    try {
      const res = await komApiClient.get(`/affiliate/${email}`);

      if (res?.data?.affiliate) {
        return true; // User is an affiliate
      }

      return false; // User is not an affiliate
    } catch (error) {
      console.error("Error checking affiliate status:", error);
      return false; // In case of any error, assume user is not an affiliate
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userData,
        setUserData,
        googleSignIn,
        signOut,
        fetchUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return context;
};
