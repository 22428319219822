import React, { useState } from "react";
import { UserAuth } from "../lib/authContext";
import icon from "../assets/images/icon.png";
import { useNavigate } from "react-router-dom";
import { Edit } from "../assets/icons/Icons";
import { isPhoneNumberValid } from "../lib/funcs";
import { updateUser } from "../lib/apis";
import { toast } from "react-toastify";
import { komApiClient } from "../lib/apiClient";

const Account = () => {
  const { user, userData, setUserData, signOut } = UserAuth();
  const navigate = useNavigate();
  const [ph, setPh] = useState(userData?.user.phone_number);
  const [phvisible, setPhVisible] = useState(true);
  const [payment_details, setPayment_details] = useState(
    userData?.affiliate.payment_details
  );
  const [paymentvisible, setPaymentVisible] = useState(true);

  const updatePhno = async () => {
    if (userData?.user.user_id && ph && isPhoneNumberValid(ph)) {
      const res = await updateUser(userData?.user.user_id, ph);
      if (res?.status === 200) {
        toast.success("Updated successfully!");
        //@ts-ignore
        setUserData((prevData) => ({
          ...prevData, // Spread the existing userData
          user: {
            ...prevData?.user, // Spread the existing user data
            phone_number: ph, // Update the phone_number field
          },
        }));
        setPhVisible(true);
      }
    }
  };

  const updatePayment = async () => {
    if (userData && payment_details) {
      console.log(payment_details);
      const res = await komApiClient.put(
        `/affiliate/payment-details/${userData.affiliate.affiliate_id}`,
        { payment_details: payment_details }
      );
      console.log(res);
      if (res?.status === 200) {
        toast.success("Updated successfully!");
        //@ts-ignore
        setUserData((prevData) => ({
          ...prevData,
          affiliate: {
            ...prevData?.affiliate,
            payment_details: payment_details,
          },
        }));
        setPaymentVisible(true);
      }
    }
  };

  return (
    <main>
      <div
        className="lg:flex max-w-screen-2xl mx-auto pt-[6rem] md:pt-32 justify-evenly"
        id="account"
      >
        {/* Left */}
        <div className="flex flex-col p-5 space-y-10 bg-white shadow-md">
          <h1 className="text-3xl border-b pb-4">My Account</h1>
          <div className="grid grid-cols-5 self-center pb-10">
            <img
              src={user?.photoURL ? user.photoURL : icon}
              className="mb-10 mr-5 object-contain rounded-full"
              height={250}
              alt={user?.displayName || ""}
            />
            <div className="col-span-5 lg:col-span-4 mx-2">
              <div className="flex flex-row items-center mb-5">
                <p>Name:</p>
                <input
                  //@ts-ignore
                  value={user ? user.displayName : ""}
                  className="ml-2 rounded-xl p-2 w-60"
                  disabled
                />
              </div>

              <div className="flex flex-row items-center mb-5">
                <p>Email:</p>
                <input
                  value={userData?.user.email}
                  className="ml-2 rounded-xl p-2 w-60"
                  disabled
                />
              </div>

              <div className="flex flex-col mb-5">
                <p>Phone number</p>
                {phvisible ? (
                  <div className="mt-2 flex flex-row">
                    <input
                      value="+91"
                      className="text-grey2 rounded-xl p-2 w-12 mr-1"
                      disabled
                    />
                    <input
                      value={userData?.user.phone_number || ""}
                      className="text-grey2 rounded-xl max-w-sm p-2 pl-4 mr-2 w-52"
                      disabled
                    />
                    <button
                      className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                      onClick={() => setPhVisible(false)}
                    >
                      <Edit />
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="my-2 flex flex-row">
                      <input
                        value="+91"
                        className="text-grey2 rounded-xl p-2 w-12 mr-1"
                        disabled
                      />
                      <input
                        type="tel"
                        id="ph"
                        pattern="\d*"
                        placeholder="Enter your phone number"
                        value={ph}
                        onChange={(e) => setPh(e.target.value)}
                        className="rounded-xl max-w-sm w-64 p-2 border"
                        maxLength={10}
                        minLength={10}
                        autoFocus
                      />
                    </div>
                    <div className="flex flex-row space-x-10 mt-2">
                      <button
                        className="select-none text-white px-3 py-2 bg-red-500 hover:bg-red-700 rounded-md focus:outline-none"
                        onClick={() => setPhVisible(true)}
                      >
                        Cancel
                      </button>
                      <button
                        className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                        onClick={updatePhno}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col mb-5">
                <p>Payment Details</p>
                <p className="text-xs text-gray-400">
                  (Preferably your UPI ID or bank details and make sure the
                  information is correct)
                </p>
                {paymentvisible ? (
                  <div className="mt-2 flex flex-row">
                    <input
                      value={userData?.affiliate.payment_details || ""}
                      className="text-grey2 rounded-xl max-w-sm p-2 pl-4 mr-2 w-52"
                      disabled
                    />
                    <button
                      className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                      onClick={() => setPaymentVisible(false)}
                    >
                      <Edit />
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="my-2 flex flex-row">
                      <input
                        placeholder="Enter your payment details"
                        value={payment_details}
                        onChange={(e) => setPayment_details(e.target.value)}
                        className="rounded-xl p-2 border w-full"
                        autoFocus
                      />
                    </div>
                    <div className="flex flex-row space-x-10 mt-2">
                      <button
                        className="select-none text-white px-3 py-2 bg-red-500 hover:bg-red-700 rounded-md focus:outline-none"
                        onClick={() => setPaymentVisible(true)}
                      >
                        Cancel
                      </button>
                      <button
                        className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                        onClick={updatePayment}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-center md:justify-start">
                <button
                  className="text-white bg-red-500 hover:bg-red-700 focus:ring-0 select-none px-3 py-2 rounded-md focus:outline-none mt-5 w-40"
                  onClick={signOut}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center p-5 bg-white shadow-md mt-5">
          <button
            className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
            onClick={() => navigate("/dashboard")}
          >
            Back to Dashboard
          </button>
        </div>
      </div>
    </main>
  );
};

export default Account;
