export const isPhoneNumberValid = (input: string): boolean => {
  // Check if the input is exactly 10 digits and contains only digits
  return /^\d{10}$/.test(input);
};

export const isEmailValid = (input: string): boolean => {
  // Basic email validation using regex
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
};

export const isPinCodeValid = (input: string): boolean => {
  // Check if the input is exactly 10 digits and contains only digits
  return input !== "" && /^\d{6}$/.test(input);
};

// Function to generate affiliate code
export const generateAffiliateCode = (fullName: string): string => {
  const nameWithoutSpaces = fullName.replace(/\s+/g, ""); // Remove spaces
  let result = "";

  // Generate random characters from the full name
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * nameWithoutSpaces.length);
    result += nameWithoutSpaces.charAt(randomIndex);
  }

  return result.toUpperCase() + "5"; // Convert to uppercase and append "5"
};
