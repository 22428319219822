import React from "react";
import { UserAuth } from "../lib/authContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../assets/images/kom_logo.webp";
import { komApiClient } from "../lib/apiClient";

const Dashboard = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = UserAuth();

  const handleCashOut = async () => {
    if (
      userData?.affiliate?.current_balance &&
      userData?.affiliate?.current_balance < 500
    ) {
      toast.error("Minimum balance of Rs.500 is required to cash out.");
    } else if (userData?.affiliate.payment_details === null) {
      toast.error('Please enter your payment details in "My Account" page.');
    } else {
      try {
        const res = await komApiClient.post("/affiliate/cashout", {
          affiliate_id: userData?.affiliate.affiliate_id,
        });
        console.log(res);
        if (res.status === 200) {
          toast.success("Cash-Out request successful!");
          //@ts-ignore
          setUserData((prevData) => ({
            ...prevData,
            affiliate: {
              ...prevData?.affiliate,
              current_balance: 0.0,
            },
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="flex justify-center min-h-screen bg-gradient-to-b from-cyan-100 to-cyan-500">
      <div className="flex flex-col items-center justify-evenly container min-w-80 p-3">
        <div className="bg-white flex flex-row items-center justify-center p-5 rounded-xl border text-xl w-full mb-5 shadow-md">
          <img
            src={logo}
            width={50}
            alt="Knots of Macrame"
            className="object-contain rounded-lg"
          />
          <h1 className="text-xl md:text-3xl ml-3">
            Knots of Macrame Affiliate
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <div className="bg-white flex flex-col p-5 rounded-xl border w-full shadow-md">
            <div className="flex flex-row items-center justify-between text-xl mb-5">
              <p>Your Affiliate Code</p>
              <p className="ml-3 bg-cyan-200 border px-3 py-2 rounded-md">
                {userData?.affiliate.affiliate_code}
              </p>
            </div>
            <p className="text-sm md:text-base">
              Boost your earnings by sharing your unique affiliate code! Earn a
              flat 15% commission on every sale you generate, while your
              customers enjoy a 5% discount on their orders. The more you share,
              the more you earn—start now and maximize your rewards!
            </p>
          </div>

          <div className="bg-white flex flex-col justify-between p-5 rounded-xl border w-full shadow-md">
            <div className="flex flex-row items-center justify-between text-xl mb-5">
              <p>Lifetime Earnings</p>
              <p className="ml-3 bg-cyan-200 border px-3 py-2 rounded-md">
                {userData?.affiliate.lifetime_earnings}
              </p>
            </div>
            <p className="text-sm md:text-base">
              Unlock unlimited earning potential with lifetime commissions!
              Every sale you make adds to your lifetime earnings, ensuring
              continuous rewards for your efforts. There’s no cap—keep promoting
              and watch your earnings grow!
            </p>
            <button
              className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 mt-5"
              onClick={() => navigate("/account")}
            >
              My Account
            </button>
          </div>

          <div className="bg-white flex flex-col justify-between p-5 rounded-xl border w-full shadow-md">
            <div className="flex flex-row items-center justify-between text-xl mb-5">
              <p>Current Balance</p>
              <p className="ml-3 bg-cyan-200 border px-3 py-2 rounded-md">
                {userData?.affiliate.current_balance}
              </p>
            </div>
            <p className="text-sm md:text-base">
              Your current balance reflects your hard-earned commissions, ready
              to be cashed out. Keep sharing your affiliate code, making more
              sales, and watch your balance increase. The more you sell, the
              more you earn!
            </p>
            <button
              className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 mt-5"
              onClick={handleCashOut}
            >
              Cash Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
