import React, { useEffect, useState } from "react";
import GoogleButton from "react-google-button";
import { UserAuth } from "../lib/authContext";
import { komApiClient } from "../lib/apiClient";
import { toast } from "react-toastify";
import logo from "../assets/images/kom_logo.webp";
import {
  generateAffiliateCode,
  isEmailValid,
  isPhoneNumberValid,
} from "../lib/funcs";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();
  const { googleSignIn, user } = UserAuth();

  const [displayCode, setDisplayCode] = useState(false);
  const [showRegenCode, setShowRegenCode] = useState(false);
  const [regenCode, setRegenCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [register, setRegister] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    affiliate_code: "",
  });

  // Use useEffect to monitor email and phone_number fields
  useEffect(() => {
    if (register.email && register.phone_number && register.full_name) {
      const newAffiliateCode = generateAffiliateCode(register.full_name);
      // console.log(newAffiliateCode);
      setRegister((prevState) => ({
        ...prevState,
        affiliate_code: newAffiliateCode,
      }));
    }
  }, [register.email, register.phone_number, register.full_name, regenCode]);

  const registerAffiliate = async () => {
    if (
      register.full_name &&
      register.phone_number &&
      isPhoneNumberValid(register.phone_number) &&
      register.email &&
      isEmailValid(register.email) &&
      register.affiliate_code
    ) {
      setLoading(true);
      setDisplayCode(true);
      try {
        const res = await komApiClient.post("/affiliate", register);
        // console.log(res);
        if (res?.status === 201) {
          toast.success("Registered successfully!");
          setRegistered(true);
        }
        if (res?.status === 400) {
          setShowRegenCode(true);
          toast.error("Please regenerate your affiliate code.");
        }
      } catch (error) {
        console.log(error);
        //@ts-ignore
        if (error?.status === 400) {
          setShowRegenCode(true);
          toast.error("Please regenerate your affiliate code and register.");
        }
      } finally {
        setLoading(false); // set loading to false once the request is done
      }
    } else if (register.full_name === "") {
      toast.error("Full name is required.");
    } else if (
      register.phone_number === "" ||
      !isPhoneNumberValid(register.phone_number)
    ) {
      toast.error("Invalid Phone number.");
    } else if (register.email === "" || !isEmailValid(register.email)) {
      toast.error("Invalid Email.");
    }
  };

  useEffect(() => {
    if (user !== null) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div className="flex flex-row mx-auto min-h-screen w-full" id="signin">
      <img
        src={logo}
        alt="Knots of Macrame"
        className="hidden lg:flex w-1/2 object-contain"
      />

      <div className="flex flex-col justify-center p-10 space-y-5 bg-white shadow-md w-full lg:w-1/2">
        <h1 className="text-xl md:text-3xl border-b pb-4 text-center">
          Knots of Macrame Affiliate
        </h1>

        {registered ? (
          <></>
        ) : (
          <>
            <p className="text-lg md:text-xl">Affiliate Registration</p>
            <input
              value={register.full_name}
              placeholder="Enter your full name"
              className="rounded-lg border p-3 w-full"
              onChange={(e) =>
                setRegister((prev) => ({ ...prev, full_name: e.target.value }))
              }
              required
            />
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
              <div className="flex">
                <input
                  value="+91"
                  className="text-gray-500 rounded-xl p-2 w-12 mr-1"
                  disabled
                />
                <input
                  value={register.phone_number}
                  placeholder="Phone Number"
                  className="rounded-lg border p-3 flex-grow"
                  onChange={(e) =>
                    setRegister((prev) => ({
                      ...prev,
                      phone_number: e.target.value,
                    }))
                  }
                  maxLength={10}
                  required
                />
              </div>
              <input
                value={register.email}
                placeholder="Email"
                className="rounded-lg border p-3"
                onChange={(e) =>
                  setRegister((prev) => ({ ...prev, email: e.target.value }))
                }
                required
              />
            </div>

            {displayCode && (
              <div className="flex flex-row items-center">
                <p>Your Affiliate Code will be:</p>
                <p className="ml-3 bg-cyan-200 p-2 rounded-md">
                  {register.affiliate_code}
                </p>

                {showRegenCode && (
                  <button
                    className="ml-3 text-xs select-none text-black p-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                    onClick={() => setRegenCode(!regenCode)}
                  >
                    Regenerate
                  </button>
                )}
              </div>
            )}

            <button
              disabled={loading}
              className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
              onClick={registerAffiliate}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 mr-2 text-black inline-block"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Register"
              )}
            </button>

            <div className="flex items-center">
              <div className="flex-grow border-t border-gray-300"></div>
              <p className="px-4">OR</p>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
          </>
        )}

        <div className="flex flex-col pb-5">
          <p className="text-lg md:text-xl mb-5">
            {registered
              ? "Thank you for registering! Please sign in below using the email address you provided."
              : "Already an Affiliate?"}
          </p>
          <GoogleButton onClick={googleSignIn} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
