import React from "react";
import { Route, Routes } from "react-router-dom";
import Account from "./screens/Account";
import SignIn from "./screens/SignIn";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./screens/Dashboard";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/account" element={<Account />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
